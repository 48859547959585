import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"

const useStyles = makeStyles(theme => ({
  root: {
    boder: "none",
    paddingTop: "40px",
    paddingBottom: "20px",
  },
  title: {
    fontWeight: "bold",
    color: "#1E88E5",
    fontSize: "36px",
  },
  subTitle: {
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  }
}))

const Home = props => {
  const classes = useStyles()
  return (
    <Container className={classes.root}>
      <Typography className={classes.title} variant="h4" align="justify" paragraph>
        {props.data.title}
      </Typography>
      <Typography className={classes.subTitle} variant="subtitle1" align="justify" paragraph>
        {props.data.descriptions}
      </Typography>
    </Container>
  )
}
export default Home