import React from "react"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import ALink from "../../../common/ALink"
import Image from "../../../common/Image"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#f5f5f599",
    padding: "60px 0",
    boxSizing: "border-box",
  },
  inner: {
    marginBottom: 0,
    [theme.breakpoints.up("sm")]: {
      marginBottom: "30px",
    },
  },
  card: {
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.18), 0 2px 10px 0 rgba(0, 0, 0, 0.15)",
    width: "100%",
    margin: "50px auto 0 auto",
    borderRadius: "20px",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    "&:hover": {
      transform: "translateY(-3px)",
      boxShadow: "0 8px 8px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.up("md")]: {
      width: "90%",
      height: "280px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "240px",
    },
  },
  content: {
    display: "flex",
  },
  contentRight: {
    width: "70%",
  },
  image: {
    width: "80px",
    height: "80px",
    [theme.breakpoints.up("md")]: {
      width: "100px",
      height: "100px",
    },
  },
  text: {
    color: "#000",
  },
  topicTitle: {
    paddingBottom: "10px",
    marginBottom: "30px",
    borderBottom: "0.1em solid gray",
  },
  link: {
    textDecoration: "none",
  },
  test_button: {
    textTransform: "none",
    display: "flex",
    margin: "20px auto",
    height: "45px",
    width: "240px",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "180px",
    },
  },
  cartItem: {
    width: "100%",
    margin: 0,
  },
}))
const TopicCard = props => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={8} className={classes.cartItem}>
          {props.data
            ? props.data.map((item, index) => {
                const path = `/topics/${item.id}`
                return (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    spacing={10}
                    key={index}
                    className={classes.inner}
                  >
                    <Typography
                      variant="h4"
                      color="textSecondary"
                      className={classes.topicTitle}
                    >
                      {item.topicTitle}
                    </Typography>
                    <Card className={classes.card}>
                      <CardActions>
                        <ALink className={classes.link} to={path}>
                          <div className={classes.content}>
                            <Grid
                              container
                              item
                              xs={4}
                              justify="center"
                              alignItems="center"
                            >
                              <Image className={classes.image} {...item.img} />
                            </Grid>
                            <div className={classes.contentRight}>
                              <CardContent>
                                <Typography
                                  variant="h4"
                                  color="textSecondary"
                                  paragraph
                                >
                                  {item.breadcrumb}
                                </Typography>
                                <Typography
                                  className={classes.text}
                                  align="justify"
                                  variant="h5"
                                  paragraph
                                >
                                  {item.description}
                                </Typography>
                                <Typography
                                  color="textSecondary"
                                  variant="h5"
                                  gutterBottom
                                >
                                  {item.order}
                                </Typography>
                              </CardContent>
                            </div>
                          </div>
                        </ALink>
                      </CardActions>
                    </Card>
                    <ALink to={`/test/${item.id}`} className={classes.link}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.test_button}
                      >
                        {item.title}
                      </Button>
                    </ALink>
                  </Grid>
                )
              })
            : null}
        </Grid>
      </Container>
    </div>
  )
}
export default TopicCard
