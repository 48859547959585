import React from "react"
import withRoot from "../components/shell/withRoot"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Topics from "../components/learning/pageContents/Topics"
function TopicsPage({  data }) {
  return (
    <React.StrictMode>
      <SEO title="Profile" />
      <Topics header={data.homeJson} data={data.allTopicsJson.nodes} />
    </React.StrictMode>
  )
}

export default withRoot(TopicsPage)
export const query = graphql`
  {
    homeJson {
      descriptions
      title
    }
    allTopicsJson {
      nodes {
        title
        id
        breadcrumb
        description
        lessonDescription
        lessonTitle
        topicTitle
        img {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
      }
    }
  }
`
