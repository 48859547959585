import React from 'react'
import Header from '../sections/Topics/Header'
import TopicCard from '../sections/Topics/TopicCard'

function Topics({header, data}) {
    return (
        <div>
          <Header data = {header}/>  
          <TopicCard data = {data}/>
        </div>
    )
}

export default Topics
